// Dependencies
import styled from 'styled-components'

export const BioWrapper = styled.div`
  padding-top: 10px;

  &:not(:last-child) {
    padding-bottom: 30px;
  }

  .section-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% / 11 * 11);

    > div {
      width: calc(100%);
    }
  }
`
