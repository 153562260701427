// Dependencies
import React from 'react'

// Components
import Hero from '../../components/Hero/Hero'
import Agenda from '../../components/Agenda/Agenda'
import Bio from '../../components/Bio/Bio'
import Videos from '../../components/Videos/Videos'
import Fotos from '../../components/Fotos/Fotos'
import Musicas from '../../components/Musicas/Musicas'
import Social from '../../components/Social/Social'
import { default as SocialLinks } from '../../components/Template/Social'
import SocialButtons from '../../components/Template/SocialButtons'

// Styles
import '../../assets/index.css'
import {
  Section,
  SectionTitle,
  SectionIntro,
  SectionGrid,
  SectionDivider,
} from '../../components/Template/Template.styled'

const AgendaTemplate = ({
  general,
  about,
  music,
  images,
  videos,
  events,
  dates,
  social,
}) => {
  return (
    <>
      <Hero noParallax {...{ images: events.heroImage }} />
      <Section>
        <SectionTitle>{events.introTitle}</SectionTitle>
        {events.introCopy && (
          <SectionIntro>
            <p dangerouslySetInnerHTML={{ __html: events.introCopy }} />
          </SectionIntro>
        )}
        <div className="col-1-1">
          <div className="content social-nav social-nav--page">
            <div>
              <SocialLinks {...general} />
            </div>
            <div className="follow-links">
              <SocialButtons {...general} />
            </div>
          </div>
        </div>
        <Agenda {...{ ...events, dates: dates.map((date) => date.node) }} />
      </Section>
      <SectionDivider />
      <SectionGrid>
        <div className="col-1-2">
          <SectionTitle>{videos.introTitle}</SectionTitle>
          <Videos {...videos} widget size={1} inline random />
        </div>
        <div className="col-1-2">
          <SectionTitle>{music.introTitle}</SectionTitle>
          <Musicas {...music} widget />
        </div>
      </SectionGrid>
      <SectionGrid>
        <div className="col-1-2">
          <SectionTitle>{about.introTitle}</SectionTitle>
          <Bio {...about} widget />
        </div>
        <div className="col-1-2">
          <SectionTitle>{images.introTitle}</SectionTitle>
          <Fotos {...images} widget />
        </div>
      </SectionGrid>
      <SectionDivider />
      <SectionGrid>
        <div className="col-1-1">
          <SectionTitle>{social.introTitle}</SectionTitle>
          {social.introCopy && (
            <SectionIntro noPadding>
              <p>{social.introCopy}</p>
            </SectionIntro>
          )}
          <div className="content social-nav social-nav--page">
            <div>
              <SocialLinks {...general} />
            </div>
            <div className="follow-links">
              <SocialButtons {...general} />
            </div>
          </div>
          <Social juicerFeedId={general.juicerFeedId} compact />
        </div>
      </SectionGrid>
    </>
  )
}

export default AgendaTemplate
