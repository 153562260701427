// Dependencies
import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// Styles
import { BioWrapper } from './Bio.styled'

// Context & Helpers
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getMicroCopy } from '../../helpers/microcopy'

const Bio = ({ sectionTitle, sectionCopy, widget = false }) => {
  const { state } = useContext(MicroCopyContext)
  const sectionCopyHtml = sectionCopy
    ? get(sectionCopy, 'childMarkdownRemark.html')
        .replace(/\r|\n|\r\n/g, '')
        .split('</p><p>')
        .map((item) => item.replace(/<\/?p>/g, ''))
    : []

  return (
    <div className="content">
      <BioWrapper>
        {sectionTitle && <h3 className="section-title">{sectionTitle}</h3>}
        {sectionCopyHtml.length > 0 && (
          <div className="section-text">
            <div>
              {(widget ? sectionCopyHtml.slice(0, 1) : sectionCopyHtml).map(
                (par, i) => (
                  <p
                    key={'column_1_' + i}
                    dangerouslySetInnerHTML={{
                      __html: !widget ? par : par + ' [...]',
                    }}
                  />
                )
              )}
            </div>
          </div>
        )}
        {widget && (
          <AniLink
            paintDrip
            hex="#ffe5cb"
            duration={1}
            className="button"
            to={'/bio'}
          >
            <span>
              {getMicroCopy({ key: `ctas.seeBio`, data: state?.value })}
            </span>
          </AniLink>
        )}
      </BioWrapper>
    </div>
  )
}

// Components PropTypes
Bio.propTypes = {
  sectionTitle: PropTypes.string,
  sectionCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  widget: PropTypes.bool,
}

export default Bio
